/* 버튼 이벤트 */
.button-event {
  cursor: pointer !important;
  user-select: none !important;
}
.button-event:hover {
  opacity: 0.8 !important;
}
.button-event:active {
  transform: translateY(1px) !important;
}

/* 메뉴 버튼 이벤트 */
.menu-button-event {
  cursor: pointer !important;
  user-select: none !important;
}
.menu-button-event:hover svg {
  color: rgb(103 232 249) !important;
}
.menu-button-event:hover span {
  color: rgb(229 231 235) !important;
}
.menu-button-event:active {
  transform: translateY(1px) !important;
}

/* 텍스트 그림자 */
.text-shadow {
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7);
}
.text-shadow-xs {
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
}

/* 그래픽 가속 */
.translate-z {
  transform: translateZ(0);
}

/* 화면에 출력 */
@keyframes ani-object-appear {
  0% {
    transform: scale(0.9) translateZ(0);
    opacity: 0;
  }
  100% {
    transform: scale(1) translateZ(0);
    opacity: 100;
  }
}
.object-appear {
  animation: ani-object-appear 0.3s cubic-bezier(0.34, 1.56, 0.64, 1) forwards;
}

/* 화면에 출력 */
@keyframes ani-object-to-left-appear {
  0% {
    transform: translateX(100px) translateZ(0);
    opacity: 0;
  }
  100% {
    transform: translateX(0) translateZ(0);
    opacity: 100;
  }
}
.object-to-left-appear {
  animation: ani-object-to-left-appear 0.3s cubic-bezier(0.34, 1.56, 0.64, 1)
    forwards;
}

/* Ant Design */

/* 버튼 비활성화 배경색을 지정 */
button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
  background-color: rgba(0, 0, 0, 0.25);
  background-image: none;
}

/* React Flow */

/* 오른쪽 하단의 로고를 숨김 */
/*.react-flow__panel.react-flow__attribution.bottom.right {*/
/*  display: none !important;*/
/*}*/

.google-map .gm-style-iw.gm-style-iw-c {
  padding: 0;
  box-shadow: none;
  border-radius: 0;
  overflow: visible !important;
}
.google-map .gm-style-iw.gm-style-iw-c .gm-style-iw-d {
  overflow: visible !important;
  max-height: none !important;
}
.google-map .gm-style-iw-tc {
  visibility: hidden;
}
.google-map button.gm-ui-hover-effect {
  visibility: hidden;
}

.google-map .gm-style .gm-style-iw-d::-webkit-scrollbar-track,
.google-map .gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece,
.google-map .gm-style .gm-style-iw-c,
.google-map .gm-style .gm-style-iw-t::after {
  background-color: transparent !important;
}
